.header_link {
    margin-right: 20px;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    transition: .7s color ease;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.droplink {
    margin-bottom: 12px;
}

.droplink:hover {
    color: #fff;
    box-sizing: border-box;
    margin-bottom: -2px;
    cursor: pointer;
    transition: .7s color ease;
}

.link:hover {
    box-sizing: border-box;
    margin-bottom: -2px;
    cursor: pointer;
    color: #fff!important;
    transition: .7s color ease;
    border-bottom: 2px solid #fff;
}

.dropdown_content {
    width: 300px;
    top: 15px;
    padding-top: 10px;
    position: absolute;
    height: fit-content;
}

.header_dropdown_link a {
    text-decoration: none;
    color: #AEBABA;
    transition: .7s color ease;
    margin-bottom: 12px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

.mb {
    margin-bottom: 12px;
}

@media screen and (max-width: 1024px) {
    .header_link {
        margin-bottom: 28px;
    }

    .dropdown_content {
        width: 300px;
        top: 15px;
        padding-top: 10px;
        height: fit-content;
        position: initial;
    }

    .link:hover {
        box-sizing: border-box;
        margin-bottom: -2px;
        cursor: pointer;
        color: #fff!important;
        transition: .7s color ease;
        border-bottom: 2px solid #fff;
        width: fit-content;
    }
}
