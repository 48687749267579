.calculate_wrapper {
    width: 880px;
    margin: 0 auto;
}

.title {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-bottom: 50px;
}

.range_wrapper {
    width: 100%;
    display: flex;
}

.range {
    min-width: 481px;
    margin-right: 75px;
    display: flex;
    flex-direction: column;
}

.period {
    width: 129px;
    margin-right: 40px;
}

.currency {
    width: 190px;
}

.range_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 8px;
}

.deposit_amount {
    margin-bottom: 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    color: #2B3F75;
}

.slidecontainer {
    width: 100%; /* Width of the outside container */
}

.slider {
    width: 100%;
    border-radius: 5px;
    position: relative;
    margin-bottom: 40px;
    color: #d3d3d3;
    cursor: pointer;
}

/*.slider:nth-child(n+1) {*/
/*    background-color: #d3d3d3;*/
/*}*/

.range_value {
    position: absolute;
}

.track {
    background-color: #5A6061;
}

.month_block {
    display: flex;
}

.month {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    border: 1px solid #5A6061;
    margin-right: 15px;
    width: 57px;
    height: 43px;
    line-height: 135%;
    cursor: pointer;
    box-sizing: border-box;
}

.month:last-child {
    margin-right: 0;
}

.month_num {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_select {
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    background-color: transparent;
    outline: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #2B3F75;
}

.my_select_option {
    width: 190px;
    height: 38px;

}

.interest {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 10px;
}

.interest_value {

    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-left: 20px;
}

.interest_desc {

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
    color: #252828;
    text-align: center;
}

.bold {

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
    color: #252828;
}

.active {
    background-color: #2B3F75;
    color: #F6F7FA;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.04em;
    border: 1px solid #2B3F75;
    width: 57px;
    height: 43px;
    line-height: 135%;
    cursor: pointer;
    box-sizing: border-box;
}

.month_num_active {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #F6F7FA;
}

.btn_block {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.my_btn {
    background-color: #C8102E;
    border: 1px solid #C8102E;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 42px;
    cursor: pointer;
}

.my_btn:hover {
    color: #fff;
    background-color: #2B3F75;
    border-color: #2B3F75;
    transition: 0.3s all ease;
}

@media screen and (max-width: 768px) {
    .range_wrapper {
        width: auto;
        display: flex;
        flex-direction: column;
        margin: 0 30px;
    }

    .range {
        min-width: initial;
        width: auto;
        margin: 0 0 24px 0;
    }

    .calculate_wrapper {
        width: auto;
    }

    .title {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        margin-bottom: 24px;
    }

    .range_title {
        font-size: 12px;
        line-height: 135%;
    }

    .month_block {
        margin-bottom: 25px;
    }

    .month {
        width: 47px;
        height: 32px;
        font-size: 12px;
        line-height: 135%;
    }

    .active {
        width: 47px;
        height: 32px;
        font-size: 12px;
        line-height: 135%;
    }

    .month_num {
        font-size: 12px;
        line-height: 135%;
    }

    .month_num_active {
        font-size: 12px;
        line-height: 135%;
    }

    .my_select {
        width: 265px;
    }

    .interest {
        flex-direction: column;
    }

    .interest_value {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        margin-left: 0;
    }

    .interest_desc {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
    }

    .bold {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
    }

    .btn_block {
        margin-top: 32px;
    }

    .my_btn {
        padding: 10px 0;
        width: 288px;
        font-size: 12px;
        line-height: 14px;
    }
}
