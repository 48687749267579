.generalInformationWrapper {
    position: relative;
}

.container {
    padding: 0 100px;
}

.cardsWrapper {
    margin-top: 170px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: fit-content;
}

.cardsContent {
    width: 1240px;
}

.card {
    position: relative;
    width: calc(25% - 20px);
    height: 556px;
    cursor: pointer;
}

.blur {
    background: rgba(53, 51, 51, 0.65);
    width: 100%;
    height: 104px;
    position: absolute;
    top: 0;
    z-index: 2;
    transition: .3s all ease;
}

.card:hover .blur {
    background: #C8102E;
    border-radius: 80px 0;
    height: 330px;
    transition: .3s all ease;
}

.cardTitleBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    position: absolute;
    z-index: 3;
    top: 40px;
    left: 20px;
}

.cardText {
    display: none;
    transition: .5s all ease;
}

.card:hover > .cardTitleBlock > .cardText {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    width: 100%;
    padding: 20px 20px 0 0;
    text-align: left;
    transition: .5s all ease;
}

.cardTitle {
    text-align: left;
}

.cardImg {
    position: absolute;
    width: 100%;
}

.longCard {
    width: 100%;
    position: relative;
    cursor: pointer;
}

.longCard img {
    width: 100%;
}

.longCardTextBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /*top: calc(50% - 12px);*/
    left: 103px;
    position: absolute;
}

.longCardText {
    display: none;
    transition: .5s all ease;
}

.cardText {
    display: none;
    transition: .5s all ease;
}

.longCard:hover > .longCardTextBlock > .longCardText {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    width: 895px;
    margin-left: 50px;
    text-align: left;
    transition: .5s all ease;
}

.longCardTitle {

}

.longBlur {
    position: absolute;
    width: calc(25% - 20px);
    height: 100%;
    left: 0;
    background: rgba(53, 51, 51, 0.65);
    transition: .5s all ease;
}

.longCard:hover .longBlur {
    background: #C8102E;
    border-radius: 0 80px;
    width: 100%;
    transition: .5s all ease;
}

.makeBlock {
    width: 100%;
    padding-left: 100px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 1278px;
    z-index: -1;
}

.makeTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #252828;
    margin-right: 19px;
}

.fake {
    height: 694px;
}

.relative {
    position: relative;
}

.makeDescription {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    position: absolute;
    top: 340px;
    left: 105px;
    width: 715px;
}

.makeDescription div {
    margin-bottom: 15px;
}

.commentsBlock {
    display: flex;
    justify-content: space-between;
}

.comment {
    width: calc(50% - 64px);
    min-height: fit-content;
}

.commentText {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.1em;
    color: #5A6061;
}

.closeQuotes {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.author {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #2B3F75;
}

.commentsBlockTwo {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    margin-bottom: 170px;
}

@media screen and (max-width: 1440px) {
    .card {
        height: 404px;
    }

    .makeBlock {
        width: 100%;
        padding-left: 100px;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 944px;
        z-index: -1;
    }
}

@media screen and (max-width: 768px) {

    .container {
        padding: 0 16px;
    }

    .cardsWrapper {
        flex-direction: column;
    }

    .card {
        position: relative;
        width: 100%;
        height: 84px;
        cursor: pointer;
        margin-bottom: 16px;
    }

    .blur {
        background: rgba(53, 51, 51, 0.65);
        width: 100%;
        height: 84px;
        position: absolute;
        top: 0;
        z-index: 2;
        transition: .3s all ease;
    }

    .cardImg {
        position: absolute;
        width: 100%;
        height: 84px;
        object-fit: cover;
    }

    .makeBlock {
        width: auto;
        padding-left: 0;
        display: flex;
        justify-content: flex-end;
        position: initial;
        z-index: -1;
        margin-top: 64px;
        flex-direction: column;
    }

    .relative {
        position: relative;
        width: auto;
    }

    .relative img {
        width: 100vw;
        height: 354px;
    }

    .makeDescription {
        position: absolute;
        top: 0;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        left: 0;
        width: auto;
        padding: 28px 16px;
    }

    .fake {
        display: none;
    }

    .commentsBlock {
        margin-top: 64px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .comment {
        width: 100%;
        min-height: fit-content;
        margin-bottom: 28px;
    }

    .commentsBlockTwo {
        flex-direction: column;
        margin-top: 0;
    }

    .makeTitle {
        font-size: 18px;
        justify-content: center;
        margin-bottom: 24px;
    }

    .card:hover {
        height: 250px;
    }

    .card:hover > .cardTitleBlock > .cardText {
        font-size: 16px;
        line-height: 135%;
    }

    .card:hover .blur {
        background: #C8102E;
        border-radius: 80px 0;
        height: 240px;
        transition: .3s all ease;
    }

    .card:hover .cardImg {
        height: 240px;
    }

    .longCard {
        display: none;
    }

    .cardsWrapper {
        margin-top: 64px;
    }
}
