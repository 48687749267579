.wrapper {
    margin: 170px 0;
    padding: 0 100px;
}

.cardsBlock {
    display: flex;
    justify-content: space-between;
}

.card {
    width: calc(25% - 20px);
    position: relative;
    cursor: pointer;
    filter: grayscale(100%);
    transition: .5s all ease;
}

.card:hover {
    filter: initial;
    transition: .5s all ease;
}

.card:hover .cardTextBlock {
    background: #C8102E;
    transition: .5s all ease;
}

.cardBg {
    width: 100%;
}

.cardTextBlock {
    background: rgba(53, 51, 51, 0.65);
    height: 25%;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: .5s all ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cardTitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.cardText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #AEBABA;
}

@media screen and (max-width: 768px) {
    .wrapper {
        margin: 64px 0;
        padding: 0 16px;
    }

    .cardsBlock {
        flex-direction: column;
    }

    .card {
        width: 100%;
        filter: initial;
        transition: .5s all ease;
        margin-bottom: 16px;
    }

    .cardTextBlock {
        background: #C8102E;
        transition: .5s all ease;
    }
}
