.wrapper {
    margin: 0 170px;
    padding: 100px 0;
}

.container {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #252828;
    width: 420px;
}


.popup_title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-bottom: 25px;
}

.popup_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    width: 379px;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    margin-bottom: 36px;
}

.form {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
}

.input_block {
    display: flex;
    flex-direction: column;
}

.input_block span {
    margin-bottom: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.input_block input {
    width: 190px;
    height: 38px;
    border: 1px solid #252828;
}

.my_btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #C8102E;
    border: 1px solid #C8102E;
    width: 140px;
    height: 39px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
    cursor: pointer ;
}

.my_btn:hover {
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

.flex {
    display: flex;
    justify-content: center;
}

.error {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #C8102E;
    margin-top: 25px;
}

.btn_block_mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .mobileWrapper {
        display: none
    }

    .wrapper {
        margin: 0 16px;
        padding: 64px 0;
        height: 500px;
    }

    .container {
        flex-direction: column;
    }

    .title {
        font-size: 18px;
        width: 100%;
    }

    .btn_block_mobile {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .my_btn {
        width: 200px;
        margin-top: 28px;
    }

}
