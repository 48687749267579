.headerWrapper {
  height: 108px;
  width: 100%;
  background: transparent;
  position: absolute;
  z-index: 999999;
}

.headerContainer {
  padding: 0 100px 0 100px;
  height: 108px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_block a {
  text-decoration: none;
}

.logoBlock {
  margin-right: 24px;
}

.dropdown_block {
  margin-left: 80px;
  height: 25px;
}

.dropdown_block:hover div {
  color: #AEBABA;
  transition: .7s color ease;
}

.link_ru {
  margin-right: 25px;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #2B3F75;
  width: min-content;
}

.right a:hover {
  color: #252828;
}

.right a:last-child {
  margin-right: 0;
  width: 100px;
}

.logo {
  cursor: pointer;
}

.loginButtonBlock {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.login {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 8px;
  text-decoration: none;
  outline: none;
}

.signUpButton {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 6px 15px;
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.signUpButton:hover {
  color: #fff;
  background: #2B3F75;
  border: 1px solid #2B3F75;
  transition: 0.3s all ease;
}

.header_link {
  margin-right: 20px;
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  transition: .7s color ease;
}

.link:hover {
  box-sizing: border-box;
  margin-bottom: -2px;
  cursor: pointer;
  color: #fff!important;
  transition: .7s color ease;
  border-bottom: 2px solid #fff;
}

/* Dropdown Button */
.dropBtn {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #2B3F75;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  display: none;
  position: absolute;
  right: 0;
  background-color: transparent;
  min-width: 144px;
  z-index: 1;
  text-align: right;
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #2B3F75;
  padding-top: 24px;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: #2B3F75;
  padding: 0;
  text-decoration: none;
  display: block;
  margin-right: 0;
  width: fit-content;
  margin-bottom: 24px;
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {
  box-sizing: border-box;
  border-bottom:2px solid #252828;
  text-align: right;
  margin-right: 0;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

.active {
  background-color: rgba(57, 57, 57, 0.61);
}

.lng {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  width: fit-content;
}

.active_lang {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  border-bottom: 2px solid #fff;
}

.lng_block {
  display: flex;
  gap: 5px;
}

.header_container_mobile {
  display: none;
}

.my_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #C8102E;
  padding: 6px 23px;
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #C8102E;
  background-color: transparent;
  cursor: pointer;
}

.my_btn:hover {
  border: 1px solid #2B3F75;
  color: #fff;
  background-color: #2B3F75;
  transition: 0.3s all ease;
}

.my_btn:hover .arr_back{
  background-color: #fff;
  transition: 0.3s all ease;
}

.arr_back {
  width: 22px;
  height: 13px;
  background-color: #C8102E;
  display: block;
  mask-image: url("../../assets/newImg/login/arrBack.svg");
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {

  .headerWrapper {
    height: 0;
  }

  .headerContainer {
    display: none;
  }

  .header_container_mobile {
    display: block;
    height: 56px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 999999;
  }

  .mob_logo_wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .logo_mob {
    width: 22px;
    height: 22px;
  }

  .login_btn_mob {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .burger_menu_btn {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
  }
}

