.iconBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 190px;
    padding-top: 10px;
    box-sizing: border-box;
    height: 160px;
}

.iconBlock:hover .iconText {
    color: #C8102E;
    font-size: 18px;
    padding-top: 20px;
    width: 160px;
    transition: .5s all ease;
}



.iconText {
    padding-top: 15px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    width: 140px;
    transition: .5s all ease;
}

.swift {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/transfer.svg");
    transition: .5s all ease;
}

.iconBlock:hover .swift {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.safety {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/safety.svg");
    transition: .5s all ease;
}

.iconBlock:hover .safety {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.crypto {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/crypto.svg");
    transition: .5s all ease;
}

.iconBlock:hover .crypto {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.my_card {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/card.svg");
    transition: .5s all ease;
}

.iconBlock:hover .my_card {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.processingCards {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/cardprocessing.svg");
    transition: .5s all ease;
}

.iconBlock:hover .processingCards {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.chart {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/chart.svg");
    transition: .5s all ease;
}

.iconBlock:hover .chart {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.money {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/money.svg");
    transition: .5s all ease;
}

.iconBlock:hover .money {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.infinite {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/infinite.svg");
    transition: .5s all ease;
}

.iconBlock:hover .infinite {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.anonymous {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/anonymous.svg");
    transition: .5s all ease;
}

.iconBlock:hover .anonymous {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.distance {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/distance.svg");
    transition: .5s all ease;
}

.iconBlock:hover .distance {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

.support {
    width: 48px;
    height: 48px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/newImg/support.svg");
    transition: .5s all ease;
}

.iconBlock:hover .support {
    background-color: #C8102E;
    transition: .5s all ease;
    margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    .iconBlock {
        width: 160px;
    }
}
