.slider_wrapper {
    height: 100vh;
}

.active {
    position: relative;
    box-sizing: border-box;
}

.my_slider {
    height: 100vh;
}

.bg {
    width: 100%;
}

.bg img {
    width: 100%;
}

.card_content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
}

.card_desc {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.335em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 24px;
}

.card_title {
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 1261px;
    margin-top: 30px;
    text-align: center;
}

.arrow_prev {
    cursor: pointer;
    position: absolute;
    left: 50px;
    top: 50vh;
}

.arrow_next {
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 50vh;
}

.btn_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 45px;
}

@media screen and (max-width: 768px) {

    .card_title {
        width: auto;
        font-weight: 700;
        font-size: 36px;
        line-height: 38px;
    }

    .bg {
        width: 100%;
        height: 100vh;
    }

    .bg img {
        width: auto;
        height: 100vh;
    }

    .logo {
        display: none!important;
    }

    .town_image {
        width: 100%;
        padding: 0 32px;
        margin-bottom: 48px;
    }

    .card_desc {
        text-align: center;
        padding: 0 16px;
        font-size: 12px;
        line-height: 14px;
        margin-top: 0;
        margin-bottom: 28px;
    }

    .arrow_prev {
        display: none!important;
    }

    .arrow_next {
        display: none!important;
    }
}
