.container {
    padding: 0 100px;
}
.descriptionTwoLine {
    display: flex;
    align-items: center;
    justify-content: center;
}

.textBlockTwoLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #252828;
    width: 820px;
    padding: 170px 0;
    margin: 0 10px;
}

.lineShort {
    height: 2px;
    background: #C8102E;
    width: 210px;
    margin: 30px 0;
}

.cardsBlock {
    display: flex;
    justify-content: space-between;
}

.card {
    width: calc(25% - 20px);
    position: relative;
    cursor: pointer;
    filter: grayscale(100%);
    transition: .5s all ease;
}

.card:hover {
    filter: initial;
    transition: .5s all ease;
}

.card:hover .cardTextBlock {
    background: #C8102E;
    transition: .5s all ease;
}

.cardBg {
    width: 100%;
}

.cardTextBlock {
    background: rgba(53, 51, 51, 0.65);
    height: 25%;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: .5s all ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cardText {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 70%;
}

.cardTextSmall {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 30%;
}


.descriptionBlock {
    padding: 170px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.textBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 1100px;
}

.line {
    height: 2px;
    background: #C8102E;
    width: 100%;
    margin: 30px 0;
}

.btnBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 170px 0;
}

@media screen and (max-width: 1440px) {
    .lineShort {
        width: 200px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0 16px;
    }

    .textBlockTwoLine {
        font-size: 16px;
        width: auto;
        padding: 0;
        margin: 0 10px;
        text-align: left;
    }

    .cardsBlock {
        flex-direction: column;
    }

    .descriptionTwoLine {
        flex-direction: column;
        margin: 64px 0;
    }

    .lineShort {
        width: 100%;
    }

    .textBlock {
        width: auto;
        font-size: 16px;
        text-align: left;
    }

    .card {
        width: 100%;
        position: relative;
        cursor: pointer;
        filter: initial;
        transition: .5s all ease;
        margin-bottom: 16px;
    }

    .cardTextBlock {
        background: #C8102E;
    }

    .descriptionBlock {
        padding: 64px 0 0 0;
    }

    .btnBlock {
        margin: 48px 0 96px 0;
    }

}
