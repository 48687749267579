.input_name {
    margin-bottom: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 8px 10px 8px 95px;
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    outline: none;
    box-sizing: border-box;
}

.input_name_block {
    margin-right: 26px;
}

.phoneInputWrapper {
    position: relative;
    width: 190px;
    height: 38px;
}

.mySelect {
    position: absolute;
    height: 38px;
    width: 90px;
    border: 1px solid #5A6061;
    border-radius: 0 5px 5px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.mySelect img {
    margin-right: 5px;
}

.optionsWrapper {
    width: 350px;
    height: 500px;
    position: absolute;
    overflow-y: scroll;
    background: #fff;
    left: 0px;
    top: 36px;
    border: 1px solid #4353AC!important;
    border-radius: 5px;
    padding: 15px 15px;
    box-sizing: border-box;
}

.none {
    display: none;
}

.arrow {
    transform: rotate(0);
    transition: .5s all ease;
}

.arrowReverse {
    transform: rotate(180deg);
    transition: .5s all ease;
}

.option {
    width: auto;
    height: 25px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    cursor: pointer;
    border-bottom: 1px solid teal;
}

.countryName {
    display: flex;
    align-items: center;
}

.flag {
    width: 28px;
}

.selectedPhonecode {
    font-size: 14px;
}
