.mainScreen {

}

.bg {
    width: 100%;
}

.bg img {
    width: 100%;
}

.content {
    position: absolute;
    top: 210px;
    left: 0;
    flex-direction: column;
    box-sizing: border-box;
}

.container {
    padding: 0 100px;
}

.pageName {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.1em;
    color: #CFD4D7;
    text-transform: uppercase;
}

.titleBlock {
    position: relative;
    z-index: 3;
    display: flex;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    color: #FFFFFF;
    width: fit-content;
    z-index: 2;
    position: relative;
}

.line {
    position: absolute;
    height: 56px;
    left: 0;
    right: 0;
    top: 40px;
    background: #C8102E;
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .bg {
        width: 100%;
        height: 326px;
    }

    .bg img {
        width: 100%;
        height: 326px;
        object-fit: cover;
    }

    .content {
        position: absolute;
        top: 180px;
        left: 0;
        flex-direction: column;
        box-sizing: border-box;
    }

    .container {
        padding: 0 16px;
    }

    .pageName {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 0.1em;
        color: #CFD4D7;
    }

    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        color: #FFFFFF;
    }

    .line {
        position: absolute;
        height: 21px;
        left: 0;
        right: 0;
        top: 16px;
        background: #C8102E;
        z-index: -1;
    }

    .titleBlock {
        position: relative;
        z-index: 3;
        display: initial;
    }
}
