.api_guide_contact_block {
    max-width: 1240px;
    margin: 0 auto;
    padding-top: 140px;
    display: flex;
    justify-content: space-between;
}

.left {
    width: 41%;
}

.right {
    width: 49%;
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    display: flex;
    align-items: center;
    color: #2B3F75;
}

.welcome_block {
    margin-left: 100px;
    margin-top: 36px;
}

.img {
    margin-right: 10px;
}

.input_name {
    margin-bottom: 8px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 8px 10px;
    width: 190px;
    height: 38px;
    border: 1px solid #5A6061;
    outline: none;
    box-sizing: border-box;
}

.input_name_block {
    margin-right: 26px;
}

.my_textarea {
    outline: none;
    resize: none;
    width: 400px;
    height: 125px;
    padding: 5px;
}


.form_text {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    color: #252828;
    margin-bottom: 20px;
}

.form_wrapper {
    display: flex;
    gap: 17px;
    width: 100%;
}

.btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.api_guide_content_block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 1240px;
    margin: 0 auto;
    padding-top: 142px;
    padding-bottom: 142px;
}

.api_guide_content_block_item {
    padding: 45px 0;
}

.api_guide_content_block_item .subtitle {
    padding-bottom: 15px;
}

.content_title {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-bottom: 20px;
}

.subtitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: #252828;
    margin-bottom: 15px;
}

.example {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-bottom: 8px;
}

.code_block {
    border: 1.5px dashed;
    padding: 20px;
    font-family: 'Roboto', sans-serif;;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #2B3F75;
    max-width: fit-content;
    margin-bottom: 20px;
}

.code_block:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
    .title {
        font-size: 48px;
        line-height: 48px;
    }

    .welcome {
        font-size: 20px;
        line-height: 20px;
    }

    .my_textarea {
        width: 100%;
        margin-right: 20px;
    }

    .inputs_block {
        flex-wrap: wrap;
    }

    .mail {
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {
    .contact_us_content {
        max-width: initial;
        margin: 0 auto;
        height: auto;
        padding-top: 140px;
        display: flex;
        flex-direction: column;
    }

    .contact_us_content {
        flex-direction: column;
        padding-top: 40px;
        margin: 0 16px;
    }

    .inputs_block {
        flex-direction: column;
    }

    .my_textarea {
        width: 288px;
        height: 117px;
        margin-right: 0;
    }

    .my_input {
        width: 288px;
    }

    .welcome_block {
        margin-left: 0;
    }

    .title {
        font-size: 28px;
        line-height: 130%;
    }

    .welcome_block {
        margin-top: 24px;
    }

    .welcome {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
    }

    .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .right {
        width: 100%;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input_name_block {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .my_btn {
        width: 288px;
        font-size: 12px;
        line-height: 14px;
    }

    .form_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form_text {
        text-align: center;
    }

    .api_guide_contact_block {
        flex-direction: column;
        padding: 40px 16px;
        justify-content: center;
    }

    .inputs_block {
        display: flex;
        flex-direction: column;
    }

    .api_guide_content_block {
        padding: 10px;
    }

    .code_block {
        word-break: break-word;
    }
}
