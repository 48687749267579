.card {
  border: 1px solid #252828;
  cursor: pointer;
}

.card:hover {
  background-color: #2B3F75;
  color: #fff;
}

.values_title {
  display: flex;
  justify-content: space-between;
}

.acc_num_title {

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #AEBABA;
}

.currency_title {

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #AEBABA;
  text-align: right;
}

.card:hover .currency_value {
  color: #fff
}

.currency_value {
  color: #2B3F75
}

.flex {
  display: flex;
  justify-content: center;
}

.input {
  width: 144px;
  height: 40px;
  background: #343434;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #fff;
  padding-left: 15px;
}

.input:hover {
  background: #4a4a4a;
}

.input:focus {
  background: #171c1a;
  border: 1px solid #e7e7e7;
  margin: -1px;
}

.size {
  width: 264px;
}

.fieldTitle {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
}

.filters {
  display: flex;
  gap: 32px;
  align-items: flex-end;
}

.btn {
  width: 193px;
  height: 36px;
  background-color: #008a5c;
  border-radius: 10px;
  color: #fff;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
}

.btn:hover {
  background: #18694e;
}

.dropBtn {
  color: white;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #171c1a;
  min-width: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/*.dropdownContent a:hover {background-color: #ddd;}*/

.dropdown:hover .dropdownContent {
  display: block;
}

.accounts {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accounts:hover {
  background: #4a4a4a;
  cursor: pointer;
}

.dropdownContent_block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownContent_block:hover {
  background: #4a4a4a;
}

@media screen and (max-width: 768px) {
  .card {
    padding: 8px!important;
  }
}

