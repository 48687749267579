.container {
    padding: 0 100px;
}

.descriptionBlock {
    padding: 170px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.textBlock {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    width: 1100px;
}

.line {
    height: 2px;
    background: #C8102E;
    width: 100%;
    margin: 30px 0;
}

.saveTime {
    background: #252828;
    height: 386px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.saveTimeTitle {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 70px;
}

.descriptionTwoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.textBlockTwoLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #252828;
    width: 820px;
    padding: 170px 0;
    margin: 0 10px;
}

.lineShort {
    height: 2px;
    background: #C8102E;
    width: 210px;
    margin: 30px 0;
}

@media screen and (max-width: 1440px) {
    .lineShort {
        width: 200px;
    }
}

@media screen and (max-width: 768px) {

    .container {
        padding: 0 16px;
    }

    .descriptionBlock {
        padding: 64px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .textBlock {
        font-size: 16px;
        width: auto;
        text-align: left;
    }

    .saveTimeTitle {
        font-size: 16px;
        margin-bottom: 28px;
    }

    .saveTime {
        height: 220px;
    }

    .textBlockTwoLine {
        font-size: 16px;
        width: auto;
        text-align: left;
        padding: 0;
    }

    .descriptionTwoLine {
        flex-direction: column;
    }

    .lineShort {
        width: 100%;
    }
}
