
.myModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.close_popup {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 62px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}

.popup_title {

    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #2B3F75;
    margin-bottom: 25px;
}

.popup_subtitle {
    font-family: 'Roboto';
    font-style: normal;
    width: 379px;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #252828;
    margin-bottom: 36px;
}

.form {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
}

.input_block {
    display: flex;
    flex-direction: column;
}

.input_block span {
    margin-bottom: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.input_block input {
    width: 190px;
    height: 38px;
    border: 1px solid #252828;
}

.my_btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #C8102E;
    border: 1px solid #C8102E;
    width: 140px;
    height: 39px;
    justify-content: center;
    padding: 10px 20px;
    position: relative;
    cursor: pointer ;
}

.my_btn:hover {
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

.flex {
    display: flex;
    justify-content: flex-start;
}

.step_one_block {
    margin-bottom: 38px;
}

.step_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #252828;
    margin-bottom: 22px;
}

.download_pdf {
    margin-left: 29px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.pdf_name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    margin-left: 12px;
    margin-right: 35px;
}

.download_img {
    cursor: pointer;
}

.contact_info_block {
    display: flex;
    margin-left: 29px;
}

.input_title {
    margin-bottom: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.phone_block {
    margin-right: 20px;
}

.upload_pdf {
    width: 140px;
    height: 39px;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.done {
    margin-left: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
}

@media screen and (max-width: 1024px) {
    .myModalContent {
        min-width: initial;
        width: auto;
        height: 450px;
        overflow: scroll;
        padding: 80px 10px 16px;
    }

    .my_input {
        width: 90%;
    }

    .phone_block {
        margin-right: 0;
    }

    .form {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .close_popup {
        margin-bottom: 38px;
        margin-top: 27px;
        margin-right: 16px;
    }

    .popup_title {
        font-size: 16px;
        letter-spacing: 0.1em;
        margin-bottom: 16px;
    }

    .popup_subtitle {
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 135%;
        letter-spacing: 0.04em;
    }

    .input_block input {
        width: 248px;
        height: 38px;
        border: 1px solid #252828;
    }

    .my_btn {
        width: 248px;
    }

    .myModal {
        top: 40px;
    }

    .contact_info_block {
        flex-direction: column;
    }

    .download_pdf {
        margin-left: 0;
    }
}
