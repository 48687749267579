.slick-dots {
    bottom: 100px!important;
}

.slick-dots li.slick-active button:before {
    opacity: 1!important;
    color: #FFFFFF!important;
}

.slick-dots li button:before {
    color: #AEBABA!important;
}

