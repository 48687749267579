.container {
    padding: 170px 100px;
}

.documentWrapper {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
}

.documentWrapper:last-child {
    margin-bottom: 0;
}

.docName {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #2B3F75;
    margin: 0 122px 0 17px;
    width: 270px;
}

.open {
    border: 1px solid #C8102E;
    padding: 11px 20px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #C8102E;
    background: transparent;
    transition: .5s all ease;
    margin-right: 15px;
}

.open:hover {
    color: #fff;
    background: #2B3F75;
    transition: .5s all ease;
    border: 1px solid #fff;
}

.download {
    padding: 11px 20px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #C8102E;
    transition: .5s all ease;
    border: none;
}

.download:hover {
    color: #fff;
    background: #2B3F75;
    transition: .5s all ease;
}

.flex {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 64px 16px;
    }

    .documentWrapper {
        display: flex;
        margin-bottom: 50px;
        align-items: flex-start;
        flex-direction: column;
    }

    .flex {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    .docName {
        width: auto;
        font-size: 16px;
        margin: 0 0 0 12px;
    }

}
