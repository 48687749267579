.footerWrapper {
  background: #CFD4D7;
}

.footerContainer {
  width: 100%;
  padding: 30px 100px;
  box-sizing: border-box;
}

/*.loginButtonBlock {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  margin-right: 20px;*/
/*}*/

/*.loginButtonBlock a {*/
/*  text-decoration: none;*/
/*}*/

/*.login {*/
/*  font-family: "Roboto";*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  text-transform: uppercase;*/
/*  color: #fff;*/
/*  margin-left: 8px;*/
/*}*/

/*.signUpButton {*/
/*  font-family: "Roboto";*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  font-size: 14px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  text-align: center;*/
/*  text-transform: uppercase;*/
/*  color: #fff;*/
/*  padding: 6px 7px;*/
/*  background: transparent;*/
/*  border: 1px solid #fff;*/
/*}*/

/*.signUpButton:hover {*/
/*  background: #2B3F75;*/
/*  border: 1px solid #2B3F75;*/
/*  transition: 0.3s all ease;*/
/*}*/

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginButtonBlock {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
}

.login {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #C8102E;
  margin-left: 8px;
  text-decoration: none;
}

.signUpButton {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #C8102E;
  padding: 6px 15px;
  background: transparent;
  border: 1px solid #C8102E;
  cursor: pointer;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.signUpButton:hover {
  color: #fff;
  background: #2B3F75;
  border: 1px solid #C8102E;
  transition: 0.3s all ease;
}

.header_block a {
  text-decoration: none;
}

.footerLine {
  height: 1px;
  background: #AEBABA;
  width: 100%;
  margin: 30px 0;
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
}

.linksBlock {
  margin-bottom: 20px;
}

.linksTitle {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #252828;
  text-decoration: none;
  transition: .5s all ease ;
  cursor: pointer;
  margin-bottom: 10px;
}

.links:hover {
  color: #FFFFFF;
  transition: .5s all ease ;
}

.links {
  margin-bottom: 10px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5A6061;
  transition: .5s all ease;
  text-decoration: none;
}

.mainPageLink {
  margin-bottom: 20px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #252828;
  text-decoration: none;
}

.mainPageLink:hover {
  color: #FFFFFF;
  transition: .5s all ease ;
}

.column {
  display: flex;
  flex-direction: column;
}

.homePageLinkBlock {
  margin-bottom: 20px;
}

.footerCopyright {
  display: flex;
  justify-content: space-between;
}

.copyrightText {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.04em;
  color: #5A6061;
}

.downloadLink {
  text-decoration: underline;
  cursor: pointer;
}
.social_links_block {
  display: flex;
}

.facebook {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  display: block;
  mask-image: url("../../assets/newImg/login/Facebook.svg");
}

.linkedin {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  margin-left: 20px;
  display: block;
  mask-image: url("../../assets/newImg/login/LinkedIN.svg");
}

.whatsapp {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  margin-left: 20px;
  display: block;
  mask-image: url("../../assets/newImg/login/WhatsApp.svg");
}

.mail {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  margin-left: 20px;
  display: block;
  mask-image: url("../../assets/newImg/login/Mail.svg");
}

.insta {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  margin-left: 20px;
  display: block;
  mask-image: url("../../assets/newImg/login/Insta.svg");
}

.signal {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  margin-left: 20px;
  display: block;
  mask-image: url("../../assets/newImg/login/Signal.svg");
}

.telegram {
  width: 28px;
  height: 28px;
  background-color: #2B3F75;
  margin-left: 20px;
  display: block;
  mask-image: url("../../assets/newImg/login/Telegram.svg");
}

.lng_block {
  display: flex;
  gap: 5px;
  margin-left: 20px;
}

.lng {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #252828;
  cursor: pointer;
  width: fit-content;
}

.active_lang {
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #252828;
  cursor: pointer;
  border-bottom: 2px solid #252828;
}

.flex {
  display: flex;
  align-items: center;
}

.mobile_footer {
  display: none;
}

@media screen and (max-width: 1024px) {
  .mobile_footer {
    display: block;
    background-color:#CFD4D7;
    width: auto;
    height: 150px;
    margin-top: 24px;
  }

  .footerWrapper {
    display: none;
  }

  .social_links_block {
    display: flex;
    justify-content: center;
    padding-top: 44px;
  }

  .facebook {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/Facebook.svg");
  }

  .linkedin {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/LinkedIN.svg");
  }

  .whatsapp {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/WhatsApp.svg");
  }

  .mail {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/Mail.svg");
  }

  .insta {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/Insta.svg");
  }

  .signal {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/Signal.svg");
  }

  .telegram {
    width: 28px;
    height: 28px;
    background-color: #252828;
    display: block;
    mask-image: url("../../assets/newImg/login/Telegram.svg");
  }

  .allRightsBlock {
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #5A6061;
    display: block;
    margin: 0 auto;
    width: 265px;
    padding-top: 28px;
    text-align: center;
  }

  .line {
    color: #5A6061;
  }

}
