.my_btn {
    width: 190px;
    height: 40px;
    background: #C8102E;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: .5s all ease;
}

.my_btn:hover {
    color: #fff;
    background: #2B3F75;
    transition: .5s all ease;
    border: 0;
}

@media screen and (max-width: 768px) {
    .my_btn {
        width: 100%;
    }
}
