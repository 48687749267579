.colorWrapper {
    background: #252828;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 170px;
}

.contentBlock {
    width: 100%;
    position: relative;
    margin: 170px 0;
}

.container {
    padding: 0 100px;
}

.bgImg {
    width: 99vw;
}

.textBlock {
    position: absolute;
    left: 100px;
    bottom: 0;
}

.text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
    width: 350px;
    margin-bottom: 15px;
}

.btnBlock {
    margin-top: 40px;
}

.bgImgMobile {
    display: none;
}

.textBlockMobile {
    display: none;
}

.textBottomMobile {
    display: none;
}

.btnBlockMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .bgImg {
        display: none;
    }

    .textBlock {
        display: none;
    }

    .bgImgMobile {
        display: block;
        width: 100%;
    }

    .textBlockMobile {
        display: block;
        position: absolute;
        right: 16px;
        bottom: 0;
    }

    .text {
        font-size: 12px;
        line-height: 135%;
        width: 128px;
    }

    .contentBlock {
        box-sizing: border-box;
        margin-bottom: 36px;
        margin-top: 64px;
    }

    .colorWrapper {
        flex-direction: column;
    }

    .textBottomMobile {
        display: block;
        color: #fff;
        margin-bottom: 28px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        padding: 0 16px;
    }

    .btnBlockMobile {
        margin-bottom: 36px;
    }

}
