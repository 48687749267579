/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 18px;
    height: 15px;
    left: 23px;
    top: 21px;
    display: none;
}

.close_btn {
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #2B3F75;
}

.bm-burger-bars:nth-child(even) {
    width: 13px;
}

.bm-burger-bars:last-child {
    width: 9px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    left: 8px;
    display: none;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
}

/* General sidebar styles */
.bm-menu {
    background: #2B3F75;
    padding: 2em 1.5em 0;
    font-size: 1.15em;
    width: auto;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Individual item */
/*.bm-item {*/
/*    display: inline-block;*/
/*}*/

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
/*.bm-item {*/
/*    display: inline-block;*/
/*    color: #d1d1d1;*/
/*    margin-bottom: 10px;*/
/*    text-align: left;*/
/*    text-decoration: none;*/
/*    transition: color 0.2s;*/
/*}*/

.bm-item:hover {
    color: #ffffff;
}

.signUpButton {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 6px 15px;
    background: transparent;
    border: 1px solid #fff;
    cursor: pointer;
}

.signUpButton:hover {
    color: #fff;
    background: #2B3F75;
    border: 1px solid #2B3F75;
    transition: 0.3s all ease;
}

.loginButtonBlock {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-decoration: none;
    color: #FFFFFF;
    margin-left: 12px;
}

.login {
    margin-left: 8px;
}

.flex {
    display: flex!important;
    align-items: center;
}

.link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-bottom: 17px;
    cursor: pointer;
}

.lng_block_mob {
    position: absolute;
    top: 8px;
    right: 20px;
    display: flex !important;
    gap: 8px;
}

.sidebar_link_wrap {
    margin-right: 20px;
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    transition: .7s color ease;
    margin-bottom: 28px;
}

.sidebar_link:hover {
    box-sizing: border-box;
    margin-bottom: -2px;
    cursor: pointer;
    color: #fff!important;
    transition: .7s color ease;
    border-bottom: 2px solid #fff;
}

.lng {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    width: fit-content;
}

.active_lang {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    border-bottom: 2px solid #fff;
}
